'use client'; // Error boundaries must be Client Components

import '../styles/globals.css';
import ErrorPageVisual from './other-components/errors/ErrorPageVisual';
import ErrorPageLayout from './other-components/errors/ErrorPageLayout';
import { Instrument_Sans } from 'next/font/google';
import localFont from 'next/font/local';
import { useEffect } from 'react';
import log from '@howl/shared-utils/misc/logger';

const inter = Instrument_Sans({
  subsets: ['latin'],
  variable: '--font-sans',
});

const ballPill = localFont({
  src: './fonts/BallPill-light.otf',
  display: 'swap',
  variable: '--font-ball-pill',
});

const foundryDit = localFont({
  src: './fonts/FoundryDit-Medium.otf',
  display: 'swap',
  variable: '--font-foundry-dit',
});

const fragmentMono = localFont({
  src: [
    {
      path: './fonts/FragmentMono-Regular.ttf',
      weight: '400',
      style: 'normal',
    },
    {
      path: './fonts/FragmentMono-Italic.ttf',
      weight: '400',
      style: 'italic',
    },
  ],
  display: 'swap',
  variable: '--font-fragment-mono',
});

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  // log error before rendering
  useEffect(() => {
    log.error(error);
  }, []);

  return (
    // global-error must include html and body tags
    <html>
      <body
        className={`font-sans h-full ${inter.variable} ${ballPill.variable} ${foundryDit.variable} ${fragmentMono.variable} has-[div[id='mobile-navbar'][data-open='true']]:overflow-hidden`}
      >
        <ErrorPageLayout>
          <ErrorPageVisual error={error} />
        </ErrorPageLayout>
      </body>
    </html>
  );
}
